.container {
  padding: 32px 24px;
  box-sizing: border-box;
  flex-basis: calc(50% - 12px);

  background: var(--white-color);
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 15px;
  cursor: pointer;
  width: 564px;
}

.cheked {
  background: #fffafc;
  border: 1px solid #e064a0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.2),
    0px 2px 4px -2px rgba(16, 24, 40, 0.12);
}

.userInfoWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.userInfo {
  display: flex;
  gap: 12px;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.mail {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.wrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title {
  font-size: 18px;
  line-height: 28px;
  color: #344054;
  margin-right: 30px;
}

.minWidth {
  min-width: 170px;
  margin-right: 0;
}

.payRate {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-color);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.genreItem {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #e064a0;
}

.skillItem {
  padding: 4px 10px 4px 12px;
  background: #fff4ed;
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #c11574;
}

.preferencesItem {
  padding: 0 10px;
  border: 1px solid #e064a0;
  border-radius: 15px;
  background-color: #e064a0;
  color: var(--white-color);
  cursor: pointer;
  line-height: 38px;
  outline: none;
}

.resume {
  min-height: 72px;
  padding: 14px;
  border: 1px solid #e064a0;
  border-radius: 12px;
  display: flex;
}

.icon {
  margin-right: 15px;
}

.resumeWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}

.resumeName {
  font-weight: 600;
  color: #344054;
}

.resumeSize {
  color: #475467;
}
