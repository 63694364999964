.label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
}

.field {
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;

  background-color: var(--white-color);
  border: 2px solid var(--secondary-text-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  outline: none;
}

.withSymbolField {
  padding: 10px 86px 10px 30px;
}

.field:hover,
.field:focus {
  border: 2px solid var(--accent-color);
}

.field::placeholder {
  color: #667085;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  display: none;
}

.customCheckbox {
  height: 16px;
  width: 16px;
  margin: 0 8px 0 0;
  background-color: var(--white-color);
  border: 1px solid var(--secondary-text-color);
  border-radius: 4px;
  position: relative;
}

.checked {
  background-color: var(--accent-color);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
  width: 100%;
}

.container::before {
  content: '$';
  position: absolute;
  bottom: 31px;
  left: 14px;
}

.container::after {
  content: 'USD';
  position: absolute;
  bottom: 31px;
  right: 14px;
}

.maxWidth {
  width: 100%;
}

.textarea {
  resize: none;
  background: var(--white-color);
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  height: 128px;
  padding: 12px 14px;
  outline: none;
  transition: all 300ms ease-in-out;
  box-sizing: border-box;
}

.textarea:hover,
.textarea:focus {
  border: 1px solid var(--accent-color);
}
