.container {
  max-width: 1260px;
  margin: 0 auto;
  padding: 24px 20px;
}

.title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 24px;
}

.line {
  margin: 0 20px 32px 20px;
  height: 1px;
  background-color: #eaecf0;
}

.tabWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.tab {
  outline: none;
  padding: 10px;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  border-radius: 20px 20px 0px 0px;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  border: none;
}

.tab:hover,
.tab:focus {
  background-color: #ffdbec;
  color: var(--accent-hover-color);
}

.activeTab {
  background-color: #ffdbec;
  color: var(--accent-color);
}

.description {
  padding: 32px 54px;
  font-size: 20px;
  line-height: 24px;
  color: #475467;
  border: 1px solid #ffb5d8;
  border-radius: 0px 18px 18px 18px;
  min-height: 80px;
  margin-bottom: 56px;
}

.subTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 24px;
}
