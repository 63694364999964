body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: var(--text-color);
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

p {
  margin: 0;
}

:root {
  --primary-color: #4caf50;
  --secondary-color: #ffa500;
  --text-color: #333333;
  --secondary-text-color: #475467;
  --white-color: #ffffff;
  --black-color: #000000;
  --background-color: #f9fafb;
  --accent-color: #e064a0;
  --accent-hover-color: #c9397f;
}

.circ-img {
  height: 20%;
  width: 20%;
  border-radius: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  margin-bottom: 5%;
}

.loginpage {
  position: absolute;
  right: 0px;
  width: 60%;
  margin: auto;
}

button {
  cursor: pointer;
}

.forgotpassword {
  width: 40%;
  margin: auto;
}
.backbutton {
  width: 100%;
}
.registerpage {
  margin: auto;
  width: 40%;
  margin-top: 15%;
}
.ulclass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
}

.nav_links a {
  width: auto;
  cursor: pointer;
  border-left: 1px solid white;
  text-transform: uppercase;
  list-style-type: none;
  padding-left: 15px;
  padding-right: 15px;
}
nav {
  background-color: #4caf50;
}

img {
  max-width: 100%;
  display: block;
  overflow: hidden;
}
.post {
  margin: auto;
  width: 50%;
}
.smallprofile {
  border-radius: 50%;
  height: 2%;
  width: 2%;
}
.feedbackvideo {
  height: 20%;
  width: 20%;
}
.identifier {
  margin-top: 3%;
  display: flex;
}
.submissionform {
  margin: auto;
  margin-top: 50px;
  text-align: center;
}
.profilepage {
  width: 50%;
  margin: auto;
}
.ulclass2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
  width: 50%;
}

.main--container {
  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(auto-fit, 300px);
  place-content: center;
  gap: 1rem;
}

.left-sidebar {
  width: 200px;
  height: 100%;
  background-color: #f2f2f2;
  padding: 10px;
  margin-right: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.left-sidebar a:hover {
  background-color: #eaeaea;
}

.left-sidebar a.active {
  color: #ff0000;
  font-weight: bold;
}

.card {
  border-radius: 4px;
  background-color: #ffff;
  box-shadow: 0 5px 10px -3px rgba(#000000, 0.5);
  padding: 20px;
}

.card img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card article {
  padding: 1em;
}

.divider {
  border-left: 1px solid black;
  height: 100px;
  margin-left: 50px;
  margin-right: 50px;
}
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.accordion {
  display: flex;
  flex-direction: column;
}

.accordion-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #eee;
  padding: 10px;
  cursor: pointer;
}

.accordion-item.active .accordion-content {
  display: block;
}


.chat-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-y: scroll;
  padding: 10px;
}

.chat-messages {
  flex-grow: 1;
}

.message {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
}

.user {
  background-color: #e6eaf0;
  align-self: flex-start;
}

.assistant {
  background-color: #f3f7f9;
  align-self: flex-end;
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}


.video-player {
  width: 300px; /* Set the initial width of the video player */
  height: 300px; /* Set the initial height of the video player */
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out; /* Add transition for smooth animation */

}

.video-container {
  position: relative;
  display: inline-block;
}

.expanded {
    width: 100%; /* Set the expanded width of the video player */
    height: 100%; /* Set the expanded height of the video player */
  }

