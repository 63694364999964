.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 320px;
}

.progressWrapper {
  display: flex;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  justify-content: center;
}

.progress {
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #eaecf0;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.step {
  height: 32px;
  width: 32px;
  background: #f8f9fc;
  border: 2px solid #eaecf0;
  border-radius: 16px;
  padding: 0;
  outline: none;
  margin: 0;
  position: relative;
  z-index: 2;
}

.active {
  box-shadow: 0px 0px 0px 4px #ffdbec;
  border: 2px solid var(--accent-color);
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: #344054;
}

.text {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #eaecf0;
}

.dot {
  fill: var(--accent-color);
}

.inProgress {
  background-color: var(--accent-color);
}
