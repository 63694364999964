.wrapper {
  margin: 24px 0;
}

.button {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  margin: 0;
  padding: 10px 18px;
  background-color: var(--accent-color);
  border: 1px solid var(--accent-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: var(--white-color);
  transition: all 300ms ease-in-out;
}

.button:hover,
.button:focus {
  background-color: var(--accent-hover-color);
}

.center {
  text-align: center;
}

.backButton {
  outline: none;
  background-color: transparent;
  border: none;
  color: var(--accent-color);
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  margin-bottom: 24px;
}

.backButton:hover,
.backButton:focus {
  color: var(--accent-hover-color);
}
