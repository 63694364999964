.performerContainer {
  display: flex;
  gap: 10px;
}

.text {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
  cursor: default;
}
