.container {
  padding: 32px 40px;
  margin: 96px auto;
  max-width: 1200px;
  background-color: var(--white-color);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
}

.forgotPass {
  font-size: 14px;
  line-height: 20px;
  color: var(--accent-color);
  font-weight: 600;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.forgotPass:hover,
.forgotPass:focus {
  color: var(--accent-hover-color);
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-right: 4px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
