.container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 32px;
}

.uploadWrapper {
  padding: 24px;
  background: var(--white-color);
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.08),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 15px;
}

.linkWrapper {
  text-align: center;
}

.link {
  font-size: 14px;
  line-height: 20px;
  color: var(--accent-color);
  font-weight: 600;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.link:hover,
.link:focus {
  color: var(--accent-hover-color);
}
