.container {
  padding: 35px 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.reviewerContainer {
  margin-bottom: 24px;
}

.empty {
  text-align: center;
  color: var(--accent-color);
  font-size: 20px;
  font-weight: 600;
}

@media screen and (min-width: 1024px) {
  .reviewerContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 0;
  }
}
