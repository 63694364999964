.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--background-color);
}

.wrapper {
  width: 100%;
  max-width: 440px;
  padding: 32px 40px;

  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background-color: var(--white-color);
}

.title {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #101828;
  margin: 34px auto 8px;
  text-align: center;
}

.subTitle {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--secondary-text-color);
  margin: 0 auto 24px;
  text-align: center;
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.forgotPass {
  font-size: 14px;
  line-height: 20px;
  color: var(--accent-color);
  font-weight: 600;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.forgotPass:hover,
.forgotPass:focus {
  color: var(--accent-hover-color);
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-right: 4px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
