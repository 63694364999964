.title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 4px;
}

.description {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.container {
  margin: 32px 0;

  padding: 24px;
  background: var(--white-color);
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.08),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 15px;
}

.preference {
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  color: #344054;
}

.reviewWrapper {
  margin: 24px auto;
  padding: 32px 24px;
  background-color: var(--white-color);
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 15px;
  max-width: 560px;
}

.userInfoWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.userInfo {
  display: flex;
  gap: 12px;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.mail {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.wrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.postTitle {
  font-size: 18px;
  line-height: 28px;
  color: #344054;
  margin-right: 30px;
}

.minWidth {
  min-width: 170px;
  margin-right: 0;
}

.payRate {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-color);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.genreItem {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #e064a0;
}

.skillItem {
  padding: 4px 10px 4px 12px;
  background: #fff4ed;
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #c11574;
}

.preferencesItem {
  padding: 0 10px;
  border: 1px solid #e064a0;
  border-radius: 15px;
  background-color: #e064a0;
  color: var(--white-color);
  cursor: pointer;
  line-height: 38px;
  outline: none;
}
