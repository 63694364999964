.title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 4px;
}

.description {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.container {
  margin: 32px 0;

  padding: 24px;
  background: var(--white-color);
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.08),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 15px;
}

.preference {
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  color: #344054;
}

.commentsContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;
}

.label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
}

.textAreaWrapper {
  width: 320px;
}

.textarea {
  resize: none;
  background: var(--white-color);
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  height: 128px;
  padding: 12px 14px;
  outline: none;
  transition: all 300ms ease-in-out;
  box-sizing: border-box;
}

.textarea:hover,
.textarea:focus {
  border: 1px solid var(--accent-color);
}

.addFieldWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.addField {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 10px 18px;
  background-color: var(--accent-color);
  border: 1px solid var(--accent-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: var(--white-color);
  transition: all 300ms ease-in-out;
  width: 200px;
  margin-bottom: 20px;
}

.addField:hover,
.addField:focus {
  background-color: var(--accent-hover-color);
}
